import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const TechImgs = () => {
  const data = useStaticQuery(graphql`
    query {
      unity: file(relativePath: { eq: "TechLogos/unity.png" }) {
        childImageSharp {
          fixed(height: 76) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }

      android: file(relativePath: { eq: "TechLogos/android.png" }) {
        childImageSharp {
          fixed(height: 76) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }

      googlecloud: file(relativePath: { eq: "TechLogos/googlecloud.png" }) {
        childImageSharp {
          fixed(height: 76) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }

      docker: file(relativePath: { eq: "TechLogos/docker.png" }) {
        childImageSharp {
          fixed(height: 76) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }

      git: file(relativePath: { eq: "TechLogos/git.png" }) {
        childImageSharp {
          fixed(height: 76) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }

      amazon: file(relativePath: { eq: "TechLogos/amazon.png" }) {
        childImageSharp {
          fixed(height: 76) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }

      laravel: file(relativePath: { eq: "TechLogos/laravel.png" }) {
        childImageSharp {
          fixed(height: 76) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }

      symfony: file(relativePath: { eq: "TechLogos/symfony.png" }) {
        childImageSharp {
          fixed(height: 76) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }

      wordpress: file(relativePath: { eq: "TechLogos/wordpress.png" }) {
        childImageSharp {
          fixed(height: 76) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }

      roots: file(relativePath: { eq: "TechLogos/roots.png" }) {
        childImageSharp {
          fixed(height: 76) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }

      woocommerce: file(relativePath: { eq: "TechLogos/woocommerce.png" }) {
        childImageSharp {
          fixed(height: 76) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }

      prestashop: file(relativePath: { eq: "TechLogos/prestashop.png" }) {
        childImageSharp {
          fixed(height: 76) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }

      angular: file(relativePath: { eq: "TechLogos/angular.png" }) {
        childImageSharp {
          fixed(height: 76) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }

      node: file(relativePath: { eq: "TechLogos/node.png" }) {
        childImageSharp {
          fixed(height: 76) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }

      sass: file(relativePath: { eq: "TechLogos/sass.png" }) {
        childImageSharp {
          fixed(height: 76) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }

      vue: file(relativePath: { eq: "TechLogos/vue.png" }) {
        childImageSharp {
          fixed(height: 76) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }

      ionic: file(relativePath: { eq: "TechLogos/ionic.png" }) {
        childImageSharp {
          fixed(height: 76) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }

      typescript: file(relativePath: { eq: "TechLogos/typescript.png" }) {
        childImageSharp {
          fixed(height: 76) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `);

  return (
    <>
      <div className="column is-one-third">
        <div className="columns is-centered is-multiline is-mobile">
          <div className="column is-one-third-mobile is-half-desktop has-text-centered"><Img fixed={data.unity.childImageSharp.fixed} alt="Unity"/></div>
          <div className="column is-one-third-mobile is-half-desktop has-text-centered"><Img fixed={data.android.childImageSharp.fixed} alt="Android"/></div>
          <div className="column is-one-third-mobile is-half-desktop has-text-centered"><Img fixed={data.googlecloud.childImageSharp.fixed} alt="Google Cloud"/></div>
          <div className="column is-one-third-mobile is-half-desktop has-text-centered"><Img fixed={data.docker.childImageSharp.fixed} alt="Docker"/></div>
          <div className="column is-one-third-mobile is-half-desktop has-text-centered"><Img fixed={data.git.childImageSharp.fixed} alt="Git"/></div>
          <div className="column is-one-third-mobile is-half-desktop has-text-centered"><Img fixed={data.amazon.childImageSharp.fixed} alt="Amazon"/></div>
        </div>
      </div>
      <div className="column is-one-third">
        <div className="columns is-centered is-multiline is-mobile">
          <div className="column is-one-third-mobile is-half-desktop has-text-centered"><Img fixed={data.laravel.childImageSharp.fixed} alt="Laravel"/></div>
          <div className="column is-one-third-mobile is-half-desktop has-text-centered"><Img fixed={data.symfony.childImageSharp.fixed} alt="Sympony"/></div>
          <div className="column is-one-third-mobile is-half-desktop has-text-centered"><Img fixed={data.wordpress.childImageSharp.fixed} alt="Wordpress"/></div>
          <div className="column is-one-third-mobile is-half-desktop has-text-centered"><Img fixed={data.roots.childImageSharp.fixed} alt="Roots"/></div>
          <div className="column is-one-third-mobile is-half-desktop has-text-centered"><Img fixed={data.woocommerce.childImageSharp.fixed} alt="Woocommerce"/></div>
          <div className="column is-one-third-mobile is-half-desktop has-text-centered"><Img fixed={data.prestashop.childImageSharp.fixed} alt="Prestashop"/></div>
        </div>
      </div>
      <div className="column is-one-third">
        <div className="columns is-centered is-multiline is-mobile">
          <div className="column is-one-third-mobile is-half-desktop has-text-centered"><Img fixed={data.angular.childImageSharp.fixed} alt="Angular"/></div>
          <div className="column is-one-third-mobile is-half-desktop has-text-centered"><Img fixed={data.node.childImageSharp.fixed} alt="Node Js"/></div>
          <div className="column is-one-third-mobile is-half-desktop has-text-centered"><Img fixed={data.sass.childImageSharp.fixed} alt="Sass"/></div>
          <div className="column is-one-third-mobile is-half-desktop has-text-centered"><Img fixed={data.vue.childImageSharp.fixed} alt="Vue Js"/></div>
          <div className="column is-one-third-mobile is-half-desktop has-text-centered"><Img fixed={data.ionic.childImageSharp.fixed} alt="Ionic"/></div>
          <div className="column is-one-third-mobile is-half-desktop has-text-centered"><Img fixed={data.typescript.childImageSharp.fixed} alt="Type Script"/></div>
        </div>
      </div>
    </>
  )
}

export default TechImgs;