import React from 'react';
import ReactDOM from 'react-dom';
import 'flickity/dist/flickity.min.css';
import '../styles/style.scss';

export default class Slider extends React.Component {
  constructor(props) {
    super(props);
    this.flickityNode = React.createRef();

    this.state = {
      flickityReady: false,
    };

    this.refreshFlickity = this.refreshFlickity.bind(this);
  }

  componentDidMount() {
    const Flickity = require('flickity');
    var slides = document.getElementsByClassName('carousel-cell');
    this.flickity = new Flickity(this.flickityNode.current, this.props.options || {});
    // this.flickity.on('scroll', () => {
    //   this.flickity.slides.forEach((slide, i) => {
    //     var image = slides[i].children[0];
    //     var x = (slide.target + this.flickity.x) * -1/4;
    //     image.style.transform = 'translateX('+ x +'px)';
    //   });
    // });
    if (!this.props.options.watchCSS || window.getComputedStyle( this.flickityNode.current, ':after' ).content.indexOf('flickity') != -1) {
      this.setState({
        flickityReady: true,
      });
    }
  }

  refreshFlickity() {
    this.flickity.reloadCells();
    this.flickity.resize();
    this.flickity.updateDraggable();
  }

  componentWillUnmount() {
    this.flickity.destroy();
  }

  componentDidUpdate(prevProps, prevState) {
    const flickityDidBecomeActive = !prevState.flickityReady && this.state.flickityReady;
    const childrenDidChange = prevProps.children.length !== this.props.children.length;

    if (flickityDidBecomeActive || childrenDidChange) {
      this.refreshFlickity();
    }
  }

  renderPortal() {
    if (!this.flickityNode.current) {
      return this.props.children;
    }

    let mountNode;

    if (!this.props.options.watchCSS || window.getComputedStyle( this.flickityNode.current, ':after' ).content.indexOf('flickity') != -1) {
      mountNode = this.flickityNode.current.querySelector('.flickity-slider');
    } else {
      mountNode = this.flickityNode.current;
    }

    if (mountNode) {
      return ReactDOM.createPortal(this.props.children, mountNode);
    }
  }

  render() {
    return [
      <div className="carousel hero-slider" key="flickityBase" ref={this.flickityNode} />,
      this.renderPortal(),
    ].filter(Boolean);
  }
}