import React from "react"
import Img from "gatsby-image"
import ProjectModal from './project-modal';
import { throws } from "assert";

export default class Project extends React.Component {
  constructor(props) {
    super(props);
    this.data = props.data;

    this.state = {
      showModal: false
   }

   this.handleOpen = this.handleOpen.bind(this);
  }

  handleOpen(e) {
    e.preventDefault();
    this.setState({
      showModal: !this.state.showModal
    });
    // if (!this.state.showModal) {
    //   document.body.classList.add('is-modal-active');
    // } else {
    //   document.body.classList.remove('is-modal-active');
    // }
  }

  render() {
    const { frontmatter, html } = this.data
    const { showModal } = this.state;
    return (
      <div className="carousel-cell">
        <div className="inner">
            <div className="columns is-vcentered">
                <div className="column is-two-fifths">
                  <Img fluid={frontmatter.image.childImageSharp.fluid} style={{margin: '0 auto',}} alt={frontmatter.alt} />
                </div>
                <div className="column is-three-fifths">
                  <h3 className="title">{frontmatter.title}</h3>
                  <p className="subtitle">{frontmatter.desc}</p>
                  <div className="tags">
                      {frontmatter.tags.map((tag, index) => (
                          <span className="tag is-dark has-text-success has-text-weight-bold" key={index}>
                              &#8226; {tag}
                          </span>
                      ))}
                  </div>

                  <ul className="content-box">
                      <li className="client hide-on-mobile is-hidden-mobile">
                          <span className="bullet"></span>
                          <span className="highlight">El cliente</span>
                          <p className="has-text-light hide-on-mobile">{frontmatter.client}</p>
                      </li>
                      <li className="challenge hide-on-mobile is-hidden-mobile">
                          <span className="bullet"></span>
                          <span className="highlight">El desafío</span>
                          <p className="has-text-light hide-on-mobile">{frontmatter.challenge}</p>
                      </li>
                      <li className="solution hide-on-mobile is-hidden-mobile">
                          <span className="bullet"></span>
                          <span className="highlight">La solución</span>
                          <p className="has-text-light hide-on-mobile">{frontmatter.solution}</p>
                      </li>
                      <li className="link hide-on-mobile hide-after-tablet is-hidden-mobile">
                          <span className="bullet"></span>
                          <a className="visit-project-button" href={frontmatter.link} target="_blank">Visitar proyecto</a>
                      </li>
                      <li className="link is-hidden-tablet is-marginless">
                          {/* <span className="bullet"></span> */}
                          <button className="visit-project-button" onClick={e => this.handleOpen(e)}>Ver detalles</button>
                      </li>
                  </ul>
                </div>
            </div>
        </div>
        {
                showModal ? (
                    <ProjectModal handler={this.handleOpen} 
                    project={{title: frontmatter.title, client: frontmatter.client, challenge: frontmatter.challenge, solution: frontmatter.solution, link: frontmatter.link}}>
                    </ProjectModal>
                ) : null
              }

      </div>
    );
  }
}