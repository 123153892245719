import React from "react";
import ReactGA from "react-ga"
import { withFormik } from "formik";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from 'yup';
import { Link } from "gatsby";


const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const validationSchema = Yup.object().shape({
    nameksljf: Yup.string()
        .min(2, 'Debe contener al menos dos letras')
        .required('Este campo es obligatorio'),
    emailksljf: Yup.string()
      .email("Esta dirección de email no es válida")
      .required('Este campo es obligatorio'),
    tel: Yup.string()
        .matches(phoneRegExp, 'El número de teléfono no es válido')
        .required('Este campo es obligatorio'),
    proyectType: Yup.string()
        .oneOf(['landing_page', 'corporativa', 'ecommerce', 'app_mobile', 'app_desktop', 'logo', 'imagen_corporativa', 'motion_graphics', '3d_model', 'animation', 'corporate_video', 'iot', 'other'], 'Selecciona un tipo de proyecto de la lista.')
        .required('Este campo es obligatorio'),
    elevatorSpeech: Yup.string()
        .min(20, 'Escribe al menos 20 letras')
        .required('Este campo es obligatorio'),
    rgpd: Yup.bool()
        .oneOf([true],'Tienes que aceptar los términos y condiciones de uso')
});
export class MyEnhancedForm extends React.Component {
    constructor(props) {
        super(props);
        this.showNotification.bind(this);
        this.closeNotification.bind(this);
        this.state = {
            notification: false,
            failed: false,
            toggleTerms: false
        }
    }

    showNotification(failed = false) {
        this.setState({
            notification: true,
            failed: failed
        });

        setTimeout(() => {
            this.setState({
                notification: false,
                failed: false
            });
        }, 5000);
    }

    toggleTerms(e) {
        e.preventDefault();
        this.setState(prevState => {
            return {toggleTerms: !prevState.toggleTerms};
        });
    }

    closeNotification() {
        this.setState({
            notification: false,
            failed: false
        });
    }

    render(){
        const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            dirty
          } = this.props
          const _this = this;
        return(
            <>
            <Formik
                initialValues={{ nameksljf:"", emailksljf: "", tel: "", proyectType: "", elevatorSpeech: "", rgpd: false, name: "", email: "" }}
                validationSchema={validationSchema}
                onSubmit={(values, {setSubmitting, resetForm}) => {
                    if (values.name || values.email) {
                        window.location.reload();
                        return;
                    }
                    fetch('https://erp.raraavislab.es/api/new/form', {
                        method: 'POST',
                        mode: 'cors',
                        cache: 'no-cache',
                        credentials: 'omit',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            name: values.nameksljf,
                            email: values.emailksljf,
                            tel: values.tel,
                            proyect_type: values.proyectType,
                            comment: values.elevatorSpeech,
                            rgpd: values.rgpd
                        })
                    }).then((response) => {
                        ReactGA.event({
                            category: 'contacto',
                            action: 'Form contact sent',
                            label: 'home',
                            value: 0
                        })
                        setSubmitting(false);
                        _this.showNotification(response.status !== 200);
                        resetForm();
                        values.rgpd = false;
                    }, err => {
                        setSubmitting(false);
                        _this.showNotification(true);
                    });
                }}
            >
                {({ handleChange, handleBlur, touched, errors, isSubmitting, values }) => (
                    <Form>
                        <div className="field">
                            <div className="control">
                                <label aria-label="Nombre">
                                    <Field autoComplete="ADF8d1Ad"
                                           id="nameksljf"
                                           name="nameksljf"
                                           placeholder="Nombre"
                                           type="text"

                                           onChange={handleChange}
                                           onBlur={handleBlur}
                                           className={
                                               'input' + (errors.nameksljf && touched.nameksljf ? " is-danger" : "")
                                           }
                                    />
                                </label>
                            </div>
                            {(errors.nameksljf && touched.nameksljf) && (
                                <p className="help is-danger">{errors.nameksljf}</p>
                            )}
                        </div>
                        <div className="field">
                            <div className="control">
                                <label aria-label="Email">
                                    <Field
                                        id="emailksljf"
                                        name="emailksljf"
                                        placeholder="Email"
                                        type="email"

                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={
                                        'input' + (errors.emailksljf && touched.emailksljf ? " is-danger" : "")
                                        }
                                    />
                                </label>
                            </div>
                            {(errors.emailksljf && touched.emailksljf) && (
                                <p className="help is-danger">{errors.emailksljf}</p>
                            )}
                        </div>
                        <div className="field">
                            <div className="control">
                                <label aria-label="Teléfono">
                                    <Field
                                        id="tel"
                                        name="tel"
                                        placeholder="Teléfono"
                                        type="number"
                                        // value={values.tel}
                                        // onChange={handleChange}
                                        // onBlur={handleBlur}
                                        className={
                                        'input' + (errors.tel && touched.tel ? " is-danger" : "")
                                        }
                                    />
                                </label>
                            </div>
                            {(errors.tel && touched.tel) && (
                                <p className="help is-danger">{errors.tel}</p>
                            )}
                        </div>
                        <div className="field">
                            <div className="control">
                                <div className={"select is-fullwidth" + (errors.proyectType && touched.proyectType ? " is-danger" : "")}>
                                    <label aria-label="Tipo de proyecto">
                                        <Field
                                            component="select"
                                            id="proyectType"
                                            placeholder="Tipo de proyecto"
                                            value={values.proyectType}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        >
                                            <option value="" label="Tipo de proyecto" disabled>Tipo de proyecto</option>
                                            <optgroup label="Web">
                                                <option value="landing_page" label="Landing page">Landing page</option>
                                                <option value="corporativa" label="Corporativa">Corporativa</option>
                                                <option value="ecommerce" label="Ecommerce">Ecommerce</option>
                                            </optgroup>
                                            <optgroup label="Apps">
                                                <option value="app_mobile" label="Aplicación movil">Aplicación movil</option>
                                                <option value="app_desktop" label="Aplicación de escritorio">Aplicación escritorio</option>
                                            </optgroup>
                                            <optgroup label="Diseño Gráfico">
                                                <option value="logo" label="Logo">Logo</option>
                                                <option value="imagen_corporativa" label="Imagen corporativa">Imagen corporativa</option>
                                                <option value="motion_graphics" label="Motion graphics">Motion graphics</option>
                                                <option value="3d_model" label="Modelado 3D">Modelado 3D</option>
                                                <option value="animation" label="Animaciones 3D/2D">Animaciones 3D/2D</option>
                                            </optgroup>
                                            <option value="corporate_video" className="has-text-weight-bold" label="Video corporativo">Video corporativo</option>
                                            <option value="iot" className="has-text-weight-bold" label="Raspberry, Arduino, IoT">Raspberry, Arduino, IoT</option>
                                            <option value="other" label="Otro">Otro</option>
                                        </Field>
                                    </label>
                                </div>
                            </div>
                            {(errors.proyectType && touched.proyectType) && (
                                <p className="help is-danger">{errors.proyectType}</p>
                            )}
                        </div>
                        <div className="field">
                            <label aria-label="Tu idea en pocas palabras...">
                                <Field
                                    component="textarea"
                                    placeholder="Tu idea en pocas palabras..."
                                    id="elevatorSpeech"
                                    name="elevatorSpeech"

                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={
                                    'textarea has-fixed-size' + (errors.elevatorSpeech && touched.elevatorSpeech ? " is-danger" : "")
                                    }
                                ></Field>
                            </label>
                            {(errors.elevatorSpeech && touched.elevatorSpeech) && (
                                <p className="help is-danger">{errors.elevatorSpeech}</p>
                            )}
                        </div>
                        {/* HONEYPOT */}
                        <div className="field ohnohoney">
                            <div className="control">
                                <label aria-label="Nombre">
                                    <Field autoComplete="DA1SD41ASW"

                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="name"
                                        name="name"
                                        placeholder="Nombre"
                                        type="text"
                                    />
                                </label>
                            </div>
                        </div>
                        <div className="field ohnohoney">
                            <div className="control">
                                <label aria-label="Email">
                                    <Field

                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="email"
                                        name="email"
                                        autoComplete="ofdadasf"
                                        placeholder="Email"
                                        type="email"
                                    />
                                </label>
                            </div>
                        </div>
                        {/* !HONEYPOT */}
                        <div className="field">
                            <div className="control">
                                <Field
                                    type="checkbox"
                                    id="rgpd"
                                    name="rgpd"
                                    checked={values.rgpd}

                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={
                                    'is-checkradio is-primary ' + (errors.rgpd && touched.rgpd ? " is-danger" : "")
                                    }
                                />
                                <label htmlFor="rgpd" aria-label="Términos y condiciones de uso" className="is-size-7-mobile has-margin-bottom-10-mobile">Acepto los <button onClick={(e) => this.toggleTerms(e)} className="link has-text-weight-bold">términos y condiciones de uso</button></label>
                                <div className={`accordion ${this.state.toggleTerms ? 'is-active' : ''}`}>
                                    <p><b>Información básica de privacidad</b></p>
                                    <p>
                                    Tus datos personales serán almacenados y tratados por Rara Avis Lab S.L., con CIF B-99513525, con el fin de atender tu petición de informarte sobre los productos y servicios de Rara Avis Lab S.L. Podrás ejercitar tus derechos de acceso, rectificación, supresión, oposición y/o demás derechos reconocidos legalmente sobre tus datos a través de ayuda@superadmin.es. Puedes consultar información detallada <a href="/privacidad-cookies/" target="_blank">aquí</a>.
                                    </p>
                                </div>
                            </div>
                            {(errors.rgpd && touched.rgpd) && (
                                <p className="help is-danger">{errors.rgpd}</p>
                            )}
                        </div>
                        <div className="field has-margin-top-30">
                            <div className="control">
                                <button type="submit" className={`button is-outlined is-white is-medium${isSubmitting ? ' is-loading' : ''}`} disabled={isSubmitting}>
                                    Lanza tu proyecto
                                </button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
            {
                this.state.notification && !this.state.failed ? (
                    <div className="notification has-text-centered is-success">
                        <button className="delete" onClick={() => this.closeNotification()}></button>
                        El formulario se ha enviado correctamente.
                    </div>
                ) : null
            }

            {
                this.state.notification && this.state.failed ? (
                    <div className="notification has-text-centered is-danger">
                        <button className="delete" onClick={() => this.closeNotification()}></button>
                        El formulario no se pudo enviar, intentelo de nuevo pasado un tiempo.
                    </div>
                ) : null
            }
            </>
        )
    }
}