import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
// import Slider from './slider';
import Slider from 'react-slick';


class Clients extends React.Component {
  constructor(props) {
    super(props);
    this.state = {render: false};
  }

  arr_alts = {
    0: "Logo Gobierno de Aragón",
    1: "Logo Wanatop",
    2: "Logo Ecomputer",
    3: "Logo Tridente",
    4: "Logo Catinred",
    5: "Logo Hook Padel",
    6: "Logo Petkus",
    7: "Logo Pastores G.C."
  };

  componentDidMount() {
    this.setState({render: true});
  }

  render() {
    let arr = Array.from(Object.keys(this.props.data), k => {return this.props.data[k]});

    let settings = {
      dots: true,
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      className: 'columns is-multiline',
      mobileFirst: true,
      responsive: [
        {
          breakpoint: 50000, // a unrealistically big number to cover up greatest screen resolution
          settings: 'unslick'
        },
        {
          breakpoint: 768,
        }
      ]
    };

    if (!this.state.render) {
      return null;
    }
    return (
      <Slider {...settings}>
        {arr.map((project, index) => (
          <div className="column is-one-quarter" key={index} data-key={index}>
            <figure><Img fluid={project.childImageSharp.fluid} alt={this.arr_alts[index]}/></figure>
          </div>
        ))}
      </Slider>
    )
  }
}

export default () => (
  <StaticQuery
    query={graphql`
      query {
        gobiernoaragon: file(relativePath: { eq: "clients/gobierno-aragon.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }

        wanatop: file(relativePath: { eq: "clients/wanatop.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }

        ecomputer: file(relativePath: { eq: "clients/ecomputer.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }

        tridente: file(relativePath: { eq: "clients/tridente.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }

        catinred: file(relativePath: { eq: "clients/catinred.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }

        hook: file(relativePath: { eq: "clients/hook.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }

        petkus: file(relativePath: { eq: "clients/petkus.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }

        pastores: file(relativePath: { eq: "clients/pastores.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `}
    render={(data) => (
      <Clients data={data}></Clients>
    )}
  />
)