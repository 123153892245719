import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import PropTypes from 'prop-types'; 
import * as bodymovin from 'lottie-web';

export default class BodyAnimation extends React.Component {
    mobileThreshold = 800;

    isMobile() {
        if (typeof window !== "undefined") {
            if (window.innerWidth < this.mobileThreshold) {
            return true;
            }
        }
        return false;
    }

    componentDidMount() {
        this.ani = bodymovin.loadAnimation({
            container: document.getElementById(this.props.name),
            renderer: 'svg',
            rendererSettings: { progressiveLoad:false, preserveAspectRatio: 'xMidYMax meet' },
            loop: true,
            autoplay: false,
            animationData: this.props.animation
        });
        if (this.props.options && this.props.options.direction) {
            this.ani.loop = false;
            // this.ani.goToAndStop(96, true);
            /* this.ani.setDirection(this.props.options.direction);
            this.ani.addEventListener('complete', () => {
                console.log(this.ani);
                this.ani.setDirection(this.ani.playDirection * -1);
                if (this.ani.playDirection > 0) {
                    this.ani.play();
                }
            }); */
        }
        if (this.props.preview) {
            document.getElementById(this.props.name + '_static').remove();
        }
        
        // Mobile auto play animations on viewport
        if (this.isMobile()) {
            this.handlePlayAnim = null;
            this.stopAnimationOnLoopComplete = null;
            window.addEventListener('scroll', () => this.playLoopWhileOnViewPort());
        }
    }

    isOnViewPort() {
        var bounding = this.ani.wrapper.getBoundingClientRect();
        return (
            bounding.top >= 0 &&
            bounding.left >= 0 &&
            bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    playLoopWhileOnViewPort() {
        if (this.props.options && this.props.options.direction) return;
        if (this.isOnViewPort()) {
            if (this.ani.isPaused) {
                this.ani.loop = true;
                this.ani.play();
            }
        } else {
            this.ani.pause();
        }
    }

    constructor(props) {
        super(props);
    }

    handlePlayAnim = () => {
        if (this.props.options && this.props.options.direction) return;
        this.ani.loop = true;
        this.ani.play();
    };
    stopAnimationOnLoopComplete = () => {
        if (this.props.options && this.props.options.direction) return;
        this.ani.loop = false;
    };
  
    render () {
        const hoverHandle = this.handlePlayAnim;
        const exitHandle = this.stopAnimationOnLoopComplete;
        return (
            <div>
                {this.props.preview ?
                <div id={this.props.name + '_static'}>
                    <Img fluid={this.props.preview}  alt=""/>
                </div>
                : '' }
                <div id={this.props.name} className={this.props.className} style={{width:'100%', height: this.props.options && this.props.options.height ? this.props.options.height : '400px'}} onMouseEnter={hoverHandle}
                    onMouseLeave={exitHandle}></div>
            </div>
        );
    }
}

BodyAnimation.propTypes = {
    animation: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired
  };