/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"


import Navbar from '../components/navbar';
import BodyAnimation from '../components/bodymovin-animation';
import TechImgs from '../components/tech-imgs-query.js';
import Clients from '../components/clients.js';
import Slider from '../components/slider.js';
import {MyEnhancedForm} from '../components/contact-form.js';
import Project from '../components/project.js';
import SEO from "../components/seo"
import ReactPlayer from 'react-player'

import '../styles/style.scss';

// SVGs section dividers START
import wwdSup from '../images/SectionDividers/wwdFondoSup.svg';
import wwdInf from '../images/SectionDividers/wwdFondoInf.svg';
import workSup from '../images/SectionDividers/trabajosFondoSup.svg';
import workInf from '../images/SectionDividers/trabajosFondoInf.svg';
import ctaSup from '../images/SectionDividers/ctaFondoSup.svg';
import ctaInf from '../images/SectionDividers/ctaFondoInf.svg';
import formInf from '../images/SectionDividers/formFondoInf.svg';
// SVGs section dividers END

import mañez from '../images/mañez.jpg';
import kuni from '../images/kuni.jpg';
import citroen from '../images/citroen.jpg';
import Layout from "../components/layout";

// Animations
const garcia = require('../images/animations/garcia.json');
const codina = require('../images/animations/codina.json');
const juan = require('../images/animations/juan.json');
const roberto = require('../images/animations/roberto.json');
const lupa = require('../images/animations/lupa.json');
const nido = require('../images/animations/nido.json');
const pincel = require('../images/animations/pincel.json');
const pollo = require('../images/animations/pollo.json');

const reel = require( '../videos/reel.mp4');
const reelposter = require( '../images/reelposter.jpg');


export const squareImage = graphql`
  fragment squareImage on File {
    childImageSharp {
      fluid(maxWidth: 200, maxHeight: 200) {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  }
`

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      logo1: file(relativePath: { eq: "logorara.png" }) {
        childImageSharp {
          fixed(width: 80, height: 86) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      heroBirds: file(relativePath: { eq: "hero-birds2.png" }) {
        childImageSharp {
          fluid(maxWidth: 693) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            presentationWidth
          }
        }
      }
      webIcon: file(relativePath: { eq: "Icons/iconoECOMERCE.png" }) {
        childImageSharp {
          fixed(width: 249, height: 109) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      customWorkIcon: file(relativePath: { eq: "Icons/iconoCustom.png" }) {
        childImageSharp {
          fixed(width: 243, height: 109) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      customDesignIcon: file(relativePath: { eq: "Icons/iconoDesign.png" }) {
        childImageSharp {
          fixed(height: 109, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      ecommerceIcon: file(relativePath: { eq: "Icons/iconoVR.png" }) {
        childImageSharp {
          fixed(width: 256, height: 115) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      ctaEgg: file(relativePath: { eq: "Icons/huevocta.png" }) {
        childImageSharp {
          fixed(width: 44, height: 78) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      roberto: file(relativePath: { eq: "Birds/roberto.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      juan: file(relativePath: { eq: "Birds/juan.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      agarcia: file(relativePath: { eq: "Birds/alvaro-garcia.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      acodina: file(relativePath: { eq: "Birds/alvaro-codina.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      },
      reelposter: file(relativePath: { eq: "reelposter.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      },
      allMarkdownRemark {
        edges {
          node {
            frontmatter {
              title
              desc
              alt
              tags
              image {
                  childImageSharp {
                      fluid {
                          ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      }
                  }
              }
              client
              challenge
              solution
              link
            }
          }
        }
      }
    }
  `);

  const flickityOptions = {
    accessibility: true,
    prevNextButtons: true,
    pageDots: true,
    setGallerySize: true,
    adaptiveHeight: true,
    arrowShape: {
      x0: 10,
      x1: 60,
      y1: 50,
      x2: 60,
      y2: 45,
      x3: 15
    }
  };

  const images = [
    mañez,
    kuni,
    citroen
  ];

  return (
    <>
    <Layout>
      <SEO></SEO>

        <section id="presentation" className="section hero">
          <div className="container is-fluid">
              <div className="columns is-vcentered">
                  <div className="column is-4 is-offset-1">
                      <span className="title">
                          <h2><small>#Zaragoza #Software #Internet #WPO</small></h2>
                          <span className="title">DESARROLLO de SOFTWARE, <br/>BIEN HECHO </span>
                      </span>
                      <p className="has-margin-top-20">
                        Tu proyecto de software a medida es como si fuera nuestro <i className="huevo-icon"></i>, y así lo trataremos.
                        Rara Avis Lab Diseña y Ejecuta el ciclo completo de <strong>Diseño</strong>, <strong>Desarrollo</strong> y <strong>Optimización de Software</strong> en Internet. 
                      </p>
                 

                      <div className="field has-margin-top-20">
                          <div className="control btn-to-form">
                              <a href="#lanza-tu-proyecto" className="button is-primary is-outlined" style={{maxWidth: "100%", fontSize: "1.25rem"}}>
                                  SOLICITAR CONTACTO
                              </a>
                          </div>
                      </div>
                  </div>
                  <div className="column has-text-right">
                      <Img fluid={data.heroBirds.childImageSharp.fluid} loading="eager" style={{maxWidth: data.heroBirds.childImageSharp.fluid.presentationWidth, margin: "0 0 0 auto"}} objectFit="contain" alt="Rara Avis Lab, desarrollamos tus ideas." />
                  </div>
              </div>
          </div>
        </section>

        <section id="servicios" className="section hero is-primary is-bold">
          <img src={wwdSup} className="top-sep width100" alt="" />
          <div className="container">
              <span className="title has-text-centered">
                <small>Incubamos en nuestro nido</small> 
                <h2 className="title">Servicios</h2>
              </span>
              <div className="columns has-space-between has-margin-bottom-50">
                  <div className="column is-3">
                      <div className="card">
                          <div className="card-image">
                              <figure className="image has-text-centered">
                                  <Img fixed={data.webIcon.childImageSharp.fixed} loading="eager" alt="Desarrollo Web y App" />
                              </figure>
                          </div>
                          <div className="card-content">
                              <div className="content">
                                  <h3 className="title has-text-centered">Web/PWA</h3>
                                  <p className="subtitle has-text-justified">Creación de Web App Progresivas. WPO optimización de rendimiento para sites. Test de Lighthouse por encima de 90. </p>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="column is-3">
                      <div className="card">
                          <div className="card-image">
                              <figure className="image has-text-centered">
                                  <Img fixed={data.customDesignIcon.childImageSharp.fixed} loading="eager" alt="Desarrollo a medida de Web, App o Diseño e Ilustración" />
                              </figure>
                          </div>
                          <div className="card-content">
                              <div className="content">
                                  <h3 className="title has-text-centered">Custom Design</h3>
                                  <p className="subtitle has-text-justified">Creación de logos, Imagen corporativa completa, Video corporativo, motion Graphics, Animaciones 3D / 2D. Rollers.</p>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="column is-3">
                      <div className="card">
                          <div className="card-image">
                              <figure className="image has-text-centered">
                                  <Img fixed={data.customWorkIcon.childImageSharp.fixed} loading="eager" alt="Desarrollo a medida de Web, App o Diseño e Ilustración" />
                              </figure>
                          </div>
                          <div className="card-content">
                              <div className="content">
                                  <h3 className="title has-text-centered">Custom Dev</h3>
                                  <p className="subtitle has-text-justified">Creación de Herramientas de Software Online / Offline para Uso Público General / Empresarial Interno.</p>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="column is-3">
                      <div className="card">
                          <div className="card-image">
                              <figure className="image has-text-centered">
                                  <Img fixed={data.ecommerceIcon.childImageSharp.fixed} loading="eager" alt="Desarrollo de Realidad Virtual y Realidad Aumentada" />
                              </figure>
                          </div>
                          <div className="card-content">
                              <div className="content">
                                  <h3 className="title has-text-centered">VR/AR</h3>
                                  <p className="subtitle has-text-justified">Creación de Apps para Gafas / Mobile de Realidad Virtual y Realidad Aumentada.</p>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>

          </div>
          <img src={wwdInf} className="bottom-sep width100" alt="" />
        </section>

        <section id="proceso" className="section">
          {/* <div className="container">
              <div className="columns is-centered">
                  <div className="column is-full-mobile is-three-quarters-desktop aspect-ratio">
                      <ReactPlayer
                          url="https://vimeo.com/379207809"
                          controls
                          config={{
                              vimeo: {
                                playerOptions: {
                                  controls: false,
                                  quality: 'auto'
                                }
                              }
                          }}
                          width='100%'
                          height='100%'
                      />
                  </div>
                  <div className="column column-text">
                      <h1 className="title">¡Descubre las ventajas de <strong>Rara Avis Lab!</strong></h1>
                      <p className="subtitle">Desarrollamos soluciones a medida para web, móvil y software especializado.</p>
                      <p className="has-margin-bottom-10">Creamos herramientas adaptadas a las necesidades y peculiaridades de tu negocio. Si una tarea se repite más de 3 veces, debes automatizarla y nuestros pájaros te ayudarán.</p>
                      <p className="">Deja que nuestros pajaros te ayudena desarrollar cualquier proyecto, por complejo que sea.</p>
                  </div>
              </div>
          </div> */}

          <div id="process" className="container">
            <div className="columns is-centered">
              <div className="column is-12">
                <h2 className="title has-text-centered">
                  Proceso
                </h2>
                <p className="subtitle has-text-centered">Método de nuestros pájaros.</p> 
              </div>
            </div>
              <div id="steps" className="columns is-variable is-6 is-centered">
                  <div className="column is-3">
                      <div className="column-content">
                          <BodyAnimation animation={lupa} name="lupa" options={{height:'150px'}}></BodyAnimation>
                          <h3 className="title">El Análisis</h3>
                          <p className="subtitle">Toma de contacto con el cliente y análisis del proyecto. Medimos el alcance y estimamos el coste. Se crea la lista de requisitos mínimos base.</p>
                      </div>
                  </div>
                  <div className="column is-3">
                      <div className="column-content">
                          <BodyAnimation animation={nido} name="nido" options={{height:'150px'}} className="has-padding-bottom-20"></BodyAnimation>
                          <h3 className="title">La Receta</h3>
                          <p className="subtitle">La Tecnología perfecta para tu proyecto aderezada con la búsqueda de la mejor estratégia de ejecución. Diseño de Interfaz y Priorización de funcionalidades.</p>
                      </div>
                  </div>
                  <div className="column is-3">
                      <div className="column-content">
                          <BodyAnimation animation={pincel} name="pincel" options={{height:'150px'}} className="has-padding-bottom-20"></BodyAnimation>
                          <h3 className="title">A Trabajar</h3>
                          <p className="subtitle">Se lleva a cabo la ejecución del proyecto. Se valida la interaccion con el diseño y se le dota de las funcionalidades planteadas. Testeo de uso con el cliente.</p>
                      </div>
                  </div>
                  <div className="column is-3">
                      <div className="column-content">
                          <BodyAnimation animation={pollo} name="pollo" options={{height:'150px'}} className="has-padding-bottom-20"></BodyAnimation>
                          <h3 className="title">Despegamos</h3>
                          <p className="subtitle">Una vez terminado el proyecto, se lanza a volar libre por el mundo, y aquí “comienza tu aventura”, tu proyecto te necesita para crecer. Feedback de usuarios y plantemaiento de mejoras.</p>
                      </div>
                  </div>
              </div>
          </div>
        </section>

        <section id="experiencias" className="section hero is-success is-bold">
          <img src={workSup} alt="" className="top-sep height100 fix-section-width"/>
          <div className="container is-fluid">
              <div className="columns is-centered">
                  <div className="column is-12">
                    <span className="title has-text-centered">
                      <small>Han levantado el vuelo</small> 
                      <h2 className="title">Experiencias</h2>
                    </span>
                  </div>
              </div>
              <div className="columns is-centered">
                  <div className="column is-full">
                      <Slider options={flickityOptions}>
                          {data.allMarkdownRemark.edges.map((project, index) => (
                              <Project data={project.node} key={index}></Project>
                          ))}
                      </Slider>
                  
                  </div>
              </div>
          </div>
          <img src={workInf} alt="" className="bottom-sep height100"/>
        </section>

        <section id="clientes" className="section">
          <div className="container">
              <h2 className="title has-text-centered">
                Clientes
              </h2>
              <p className="subtitle has-text-centered">
                Ya en nuestro nido 
              </p>
              <Clients></Clients>
          </div>
        </section>

        <section id="cta" className="section hero is-success is-bold">
          <img src={ctaSup} alt="" className="top-sep fix-section-width"/>
          <div className="container has-text-centered">
              <div className="columns is-centered">
                  <div className="column is-two-thirds">
                  <Img fixed={data.ctaEgg.childImageSharp.fixed} className="has-margin-bottom-20" alt="" />
                      <h2 className="title">
                        Tu idea hecha realidad
                      </h2>
                      <p className="subtitle">
                        Tenemos conocimientos de ventas, empatía y una dilatada experiencia en desarrollo para que tu proyecto eche a volar.
                      </p>
                      <a href="#lanza-tu-proyecto" className="button is-success is-inverted is-outlined">Lanza tu proyecto</a>
                  </div>
              </div>
          </div>
          <img src={ctaInf} alt="" className="bottom-sep fix-section-width"/>
        </section>

        <section id="lanza-tu-proyecto" className="section hero is-primary is-bold">
          <img src={wwdSup} className="top-sep width100" alt=""/>
          <div className="container">
              <div className="columns is-centered">
                  <div className="column">
                      <span className="title is-spaced">Formulario de Solicitud de Contacto</span>
                      <h2 className="subtitle">Una vez recibamos tu solciitud te llamaremos</h2>
                      <p className="has-margin-bottom-25">Te asesoraremos y ayudaremos a elegir la mejor opción y la más adecuada para tu lanzar tu idea/proyecto. 
                        Nuestro compromiso de sinceridad y buen hacer te permitirá tener una segunda opinión antes de decidir con quién trabajar.</p>
                      <p className="">Solo utilizaremos tu información de contacto para contactarte sobre tu proyecto/idea.</p>
                  </div>
                  <div className="column">
                      <MyEnhancedForm/>
                  </div>
              </div>
          </div>
          <img src={formInf} className="bottom-sep height100 fix-section-width" alt=""/>
        </section>

        <section id="tech" className="section">
          <div className="container">
              <div className="columns is-centered is-multiline">
                  <TechImgs/>
              </div>
          </div>
        </section>

        {/* <footer className="footer hero is-primary is-bold">
          <div className="level">
              <p className="level-item has-text-centered">
                  <a href="#servicios" className="link has-text-white">Servicios</a>
              </p>
              <p className="level-item has-text-centered">
                  <a href="#proceso" className="link has-text-white">Proceso</a>
              </p>
              <p className="level-item has-text-centered">
                  <a href="#experiencias" className="link has-text-white">Experiencias</a>
              </p>
              <p className="level-item has-text-centered">
                  <a href="#clientes" className="link has-text-white">Clientes</a>
              </p>
          </div>
      </footer> */}
      <a className="mail-button" href="#lanza-tu-proyecto">
        <span className="icon-mail"></span>
      </a>
    </Layout>
    </>
  )
}

export default IndexPage
