import React, {useLayoutEffect} from 'react';
import ReactDOM from 'react-dom';
import 'flickity/dist/flickity.min.css';
import '../styles/style.scss';

export default class ProjectModal extends React.Component {
  constructor(props) {
    super(props);
    this.element = document.createElement( 'div' );
  }

  componentDidMount() {
    document.getElementById('gatsby-focus-wrapper').appendChild(this.element);
  }

  componentWillUnmount() {
    document.getElementById('gatsby-focus-wrapper').removeChild( this.element );
  }

  render() {
    return ReactDOM.createPortal(
        <div id="project-modal" className="modal modal-full-screen is-active is-open">{/* is-open */}
            <div className="modal-background"></div>
            <div className="modal-content">
            <div id="project-modal-header">
                <span className="project-modal-title">{this.props.project.title}</span>
                <a role="button" onClick={this.props.handler} className="navbar-burger is-active" aria-label="menu" aria-expanded="false">
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>
            </div>
            <div id="project-modal-body">
                <ul className="project-modal-ul">
                    <li className="project-modal-li">
                        <p className="subtitle">El cliente</p>
                        <p className="content">{this.props.project.client}</p>
                    </li>
                    <li className="project-modal-li">
                        <p className="subtitle">El desafío</p>
                        <p className="content">{this.props.project.challenge}</p>
                    </li>
                    <li className="project-modal-li">
                        <p className="subtitle">La solución</p>
                        <p className="content">{this.props.project.solution}</p>
                    </li>
                    <li className="project-modal-li">
                        {/* <a href="#" className="link">Visitar proyecto</a> */}
                        <a href={this.props.project.link}  target="_blank" className="button is-large is-text has-text-link is-fullwidth">Visitar proyecto</a>
                    </li>
                </ul>
            </div>
            </div>
        </div>,
        this.element
    );
  }
}

function withHook(Component) {
  return function WrappedComponent(props) {
    const myHookValue = useLockBodyScroll();
    return <Component {...props} />;
  }
}

function useLockBodyScroll() {
  useLayoutEffect(() => {
   // Get original body overflow
   const originalStyle = window.getComputedStyle(document.documentElement).overflow;  
   // Prevent scrolling on mount
   document.documentElement.style.overflow = 'hidden';
   // Re-enable scrolling when component unmounts
   return () => document.documentElement.style.overflow = originalStyle;
   }, []); // Empty array ensures effect is only run on mount and unmount
}

ProjectModal = withHook(ProjectModal);